import Phaser from 'phaser'
import {getUrlVars, getResizeRatioByWidth, highlightButton} from '../helper/Util'
import LoadingAnimation from '../components/LoadingAnimation'
import {getOptionsForCase, preloadQuizImage, getAllUIBUttonIDs, preloadPopupImages, preloadUIImages, preloadPopupBox, setQuizAssets, preloadUtilitiesImages} from '../helper/Assets'

import ToolButtonsContainer from '../components/ToolButtonsContainer'
import ObjectButtonsContainer from '../components/ObjectButtonsContainer'
import CompoundLabelBuilder from '../components/CompoundLabelBuilder'
import DraggablePopup from '../components/DraggablePopup'
import DraggableCompoundPopup from '../components/DraggableCompoundPopup'

export default class WhiteboardScene extends Phaser.Scene
{
	constructor()
	{
		super('whiteboard-scene')

        const urlParams = getUrlVars()
        //this.wbid = urlParams.get("wbid")
        this.questionID = urlParams.get("questionID")
        this.questionNumber = urlParams.get("questionNumber")
        //this.level = urlParams.get("level")
        this.templateID = urlParams.get("templateID")
        this.labelSize = urlParams.get("labelSize")
        this.AUTOID = urlParams.get("AUTOID")
        this.courseid = urlParams.get("courseid")

        //this.UIOptions = getPreloadAssets(this.templateID)
        this.UIOptions = getOptionsForCase(this.templateID, this.courseid)
        //console.log("this.UIOptions: ", this.UIOptions)
        this.allObjectBtns = []
        //console.log("WhiteboardScene [constructor] date: ", Date.now() , " questionNumber: ", this.questionNumber , ' templateID: ', this.templateID, " questionID: ", this.questionID, " AUTOID: ", this.AUTOID, " courseid: ", this.courseid)
        
	}

	preload()
    {
        this.load.crossOrigin = "anonymous";
        this.gamesize = this.sys.game.scale.gameSize;
        this.primaryColorHex = 0x1073AB
        this.loadingAnimation = new LoadingAnimation(this,this.gamesize._width/2,this.gamesize._height/2,100,100)
        // base URLs ============================================
        this.baseURL = 'https://d2e28hl4ivk8fj.cloudfront.net/images/';
        //this.baseURL = 'https://rcm-assets.s3.amazonaws.com/images/';
        this.baseWhiteboardURL = this.baseURL + "whiteboard/"
        this.baseLabelsURL = this.baseWhiteboardURL + "labels/";
        this.baseUIURL = this.baseWhiteboardURL  + "UI/";
        this.basePopupURL = this.baseWhiteboardURL + "popup/";
        //this.baseQuizURL = this.baseWhiteboardURL + "quiz/";
        this.baseQuizURL = 'https://digitallearnings3bucket.s3.us-west-2.amazonaws.com/SightReading/graphics/';
        // ======================================================

        // load the quiz image ===============================
        preloadQuizImage(this.baseQuizURL, this.questionID, this.questionNumber , this)
        // ===================================================

        // TEMP ===============================
        //this.load.image('logo', 'https://rcm-assets.s3.amazonaws.com/images/delete-small.png')
        // ====================================

        this.showLog("UIOptions: " + this.UIOptions)
        // load UI graphics ==================
        preloadUIImages(getAllUIBUttonIDs(this.UIOptions), this.baseUIURL, this)
        // ===================================

        // load utilities ====================
        preloadUtilitiesImages(['measuring-tape'], this.baseURL, this)
        // ===================================

        // load images for popup options =======
        preloadPopupImages(this.UIOptions,this.basePopupURL,this.baseLabelsURL, this)
        // =====================================
         preloadPopupBox(this, this.basePopupURL)

        setQuizAssets(this.templateID, this)
    }

    create()
    {

        //temp ===============
        //console.log("this scene: " + this.scene)
        // ===================

        // resizing ==========
        const gameWidth = this.gamesize._width
 
        //this.game.canvas.getContext("2d").imageSmoothingQuality = "high";
        //console.log("this.game.canvas.getContext(2): " + this.game.canvas.getContext("2d"))
        //console.log("gameWidth: " + gameWidth)
        //this.scene.parent.width = Math.round(gameWidth);
        // ===================

        const marginRatio = 0.04
        const margin = this.gamesize._width * marginRatio

   
        var quizImage = this.add.image((this.gamesize._width / 2) - (margin / 2), this.gamesize._height / 2, 'quizImage');

        const imageWidth = this.gamesize._width - (margin * 2)
        //const imageRatio =  getResizeRatioByWidth(quizImage,imageWidth)
        //quizImage.displayWidth = imageWidth
        //quizImage.scaleY = imageRatio
        quizImage.setScale(getResizeRatioByWidth(quizImage,imageWidth));
        

        quizImage.x = this.gamesize._width / 2

        //console.log("this.gamesize._height: ", this.gamesize._height, " quizImage.height: ", quizImage.height * quizImage._scaleY)
        //console.log("quizImage: ", quizImage)
        quizImage.y = (this.gamesize._height / 2) - ((this.gamesize._height - (quizImage.height * quizImage._scaleY)) / 3)

        
        //quizImage.y = (quizImage.height / 2)

        this.currTool = 'pen';
        this.clicked = false;
        this.size = 2;
        this.itemID = 0;
        this.distance = this.size;
        this.lastPosition = new Phaser.Math.Vector2();
        this.current = null;
        this.previous = null;
        this.selectedObjects = []
		this.curve = null;
        this.objArr = Array();

        // UI tool buttons ============================
        this.toolButtons = getAllUIBUttonIDs(this.UIOptions)

        const marginbottom = 0

        //console.log('window.devicePixelRatio: ', window.devicePixelRatio)
        const toolButtonSize = 30 * window.devicePixelRatio
        const totalButtons = this.toolButtons.length
        const toonButtonMargin = marginbottom * window.devicePixelRatio
        const toolButtonsContainerMarginBottom = 15 * window.devicePixelRatio
        const toolButtonsContainerWidth = toolButtonSize * ((totalButtons * 2) + 1)
        const toolButtonsContainerHeight =  toolButtonSize + (toonButtonMargin * 2)
        // @ts-ignore
        const toolButtonsContainerX = ((this.gamesize._width / 2)) 
        // @ts-ignore
        const toolButtonsContainerY = this.gamesize._height - ((toolButtonsContainerHeight / 2) + toolButtonsContainerMarginBottom)

        // main UI buttons at the bottom
        this.toolButtonsContainer = new ToolButtonsContainer(this, toolButtonsContainerX, toolButtonsContainerY, toolButtonsContainerWidth, toolButtonsContainerHeight)
        // default popup box
        this.popupContainer = new ObjectButtonsContainer(this,0,0,Math.floor(this.gamesize._width), Math.floor(this.gamesize._height))
        // compound label builder popup box
        this.compoundLabelBuilder = new CompoundLabelBuilder(this,0,0,this.gamesize._width,this.gamesize._height,"RootQuality")

        // draggable compound label builder popup box
        this.draggableCompoundPopupContainer = new DraggableCompoundPopup(this,0,0,this.gamesize._width,this.gamesize._height,"RootQuality")

        // draggable popup box
        this.draggablePopupContainer = new DraggablePopup(this,0,0,Math.floor(this.gamesize._width), Math.floor(this.gamesize._height))

        // stop the loading animation ====================
        this.loadingAnimation.removeLoadingAnimation(this)
        //================================================

        // TEMP ==========================
        /*
        const logo = this.physics.add.image(400, 100, 'logo')
        logo.setVelocity(50, 300)
        logo.setBounce(1, 1)
        logo.setCollideWorldBounds(true)
        */
        // ===============================


        // code for drawing lines ==========
        this.input.on('pointerdown', function (pointer) {
            if (this.clicked === true) {
                return;
            }
            if (this.currTool !== "pen") {
                return;
            }
            this.lastPosition.x = pointer.x;
            this.lastPosition.y = pointer.y;
            this.createLine(pointer)
        }, this);

        this.input.on('pointermove', function (pointer) {
            if (this.clicked === true) {
                return;
            }
            if (this.currTool !== "pen") {
                return;
            }
            if (pointer.isDown)
            {
                this.updateLine(pointer)
            }
        }, this);
        
        this.input.on('pointerup', function (pointer){
            if (this.clicked === true) {
                return;
            }
            if (this.currTool !== "pen") {
                return;
            }
            this.pushLine()
        }, this);
        // =================================

        highlightButton(this.currTool, this)


        // done loading, so call this function
        this.doneLoading()
    }

    showLog(str) {
       // console.log(str)
    }

    doneLoading() {
        this.showLog("[doneLoading] AUTOID: " + this.AUTOID)
        window.WBLoaded(this.AUTOID)
    }


    // functions for lines ======================
    createLine(pointer) {
        this.splineGraphics = this.add.graphics();
        this.curve = new Phaser.Curves.Spline([ pointer.x, pointer.y ]);
    }
    updateLine(pointer) {

        if (this.curve === null) {
            return
        }
        var x = pointer.x;
        var y = pointer.y;
        if (Phaser.Math.Distance.Between(x, y, this.lastPosition.x, this.lastPosition.y) > this.distance)
        {
            this.lastPosition.x = x;
            this.lastPosition.y = y;
            this.previous = this.current;
            this.curve.addPoint(x, y);
            this.splineGraphics.lineStyle(this.size * 1.5, this.primaryColorHex);
            this.splineGraphics.fillStyle(0xFF0000, 1.0);
            this.curve.draw(this.splineGraphics, 512);
        }
    }
    pushLine() {
        if (this.curve === null) {
            return
        }
        if (this.curve !== null) {
            this.curve = null;
        }
    }
    // ==========================================

}
