import Phaser from 'phaser'

export default class CompoundLabelUIButton extends Phaser.GameObjects.Sprite {
    constructor(scene, x,y,width,height, id, parent){
        super(scene, x, y, width, height)
        this.compoundUndoButton = null
        this.compoundCreateButton = null
        this.compoundUIbutton = null
        this.scene = scene
        this.compoundBuilder = parent
        this.setCompoundLabelUIButton(scene, x,y, id, this)
    }

    setCompoundLabelUIButton(scene, x,y, id, parent) {
        scene.showLog("[setCompoundLabelUIButton] id: " + id + " x: " + x + " y: " + y)
        

        const compoundUIbutton = scene.add.sprite(x, y, id).setInteractive()
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
            //scene.handleClick(id)
            if (id==="undoButton") {
                parent.compoundBuilder.onUndo()
                //scene.compoundLabelBuilder.onUndo()
            } else if (id==="createButton") {
                parent.compoundBuilder.onCreate()
                //scene.compoundLabelBuilder.onCreate()
            }

            scene.clicked = true
        }, scene)
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, () => {
            scene.clicked = false
        },scene)

        parent.compoundUIbutton = compoundUIbutton

        if (id==="undoButton") {
            parent.compoundUndoButton = compoundUIbutton

        } else if (id==="createButton") {
            parent.compoundCreateButton = compoundUIbutton
        }
    }
}