import DraggableLabel from '../components/DraggableLabel'
import DraggableCompoundLabelContainer from '../components/DraggableCompoundLabelContainer'

function showLog(str) {
    //console.log(str)
}

export function getUrlVars ()  {
    const urlString = window.location.search
    showLog("[getUrlVars] urlString: " + urlString);
    const urlParams = new URLSearchParams(urlString);
    return urlParams;
}

export function getResizeRatioByWidth(image, targetWidth){
    var imageWidth = image.width;
    return targetWidth / imageWidth
}


export function highlightButton (id, scene) {
    showLog("[highlightButton] id: " + id)
    //console.log("this.toolButtonsContainer.toolButtons: ", this.toolButtonsContainer.toolButtons)
    for (var i = 0; i <scene.toolButtonsContainer.toolButtons.length; i++) {
        const button = scene.toolButtonsContainer.toolButtons[i]
        //console.log("button", button)
    
        if (button.id === id) {
            button.toggleActive(true)
        } else {
            button.toggleActive(false)
        }
    }
}

export function getUtility(id, scene, x, y) {
    return scene.add.sprite(x,y,id)
}

export function getContainerPiece(type, scene, x,y) {
    //scene.showLog("[getContainerPiece] type: " + type)
    var piece = null
    switch (type) {
        case "top-left":
            piece = scene.add.sprite(x,y,'popup-top-left')
            break;
        case "top":
            piece = scene.add.sprite(x,y,'popup-top')
            break;
        case "top-right":
            piece = scene.add.sprite(x,y,'popup-top-right')
            break;
        case "right":
            piece = scene.add.sprite(x,y,'popup-right')
            break;
        case "bottom-right":
            piece = scene.add.sprite(x,y,'popup-bottom-right')
            break;
        case "bottom":
            piece = scene.add.sprite(x,y,'popup-bottom')
            break;
        case "bottom-left":
            piece = scene.add.sprite(x,y,'popup-bottom-left')
            break;
        case "left":
            piece = scene.add.sprite(x,y,'popup-left')
            break;
        case "blank":
            piece = scene.add.sprite(x,y,'popup-blank')
            break;
        
        default:
            piece = scene.add.sprite(x,y,'popup-blank')
            break;
    }
    return piece.setAlpha(0)
}

export function handleClick (id, scene)  {
    showLog("[handleClick] id: " + id + " scene: " + scene)

    const isDraggable = true

    var compoundType = ""

    if (id.includes("compound")) {
        var id_string = id.split("-");
        id = id_string[0];
        compoundType = id_string[1];
    }

    showLog("id: " + id + " compoundType: " + compoundType)

    // UPDATE THIS SWITCH STATEMENT TO ADD UI CATEGORIES
    switch (id) {
        case "delete":
            refreshThis()
            break;
        case "pen":
            highlightButton(id, scene)
            scene.currTool = id
            if (isDraggable) {
                scene.draggablePopupContainer.hideContainer()
            } else {
                scene.popupContainer.hideContainer()
            }
            
            break;
        case "letter":
        case "note":
        case "number":
        case "counts":
        case "dynamics":
        case "finger":
        case "accidentals":
        case "functionalChordSymbols":
        case "rootQualityChordSymbols":
        case "markingSymbol":
        case "quality":
        case "beatPatterns":
        case "colouredCircles":
        case "intervals":
        case "direction":
        case "prepfinger":
            highlightButton(id, scene)
            scene.currTool = id
            if (isDraggable) {
                scene.draggablePopupContainer.setObjectButtonsContainer()
            } else {
                scene.popupContainer.setObjectButtonsContainer()
            }
            
            //
            break;

        case "test":
            highlightButton(id, scene)
            scene.currTool = id
            scene.draggablePopupContainer.setObjectButtonsContainer()
            break;

        case "compound":
            const id_string = id + "-" + compoundType
            if (isDraggable) {

                if (scene.draggableCompoundPopupContainer.isOpen) {
                    if (isObjectButton(id_string, scene)) {
                        scene.draggableCompoundPopupContainer.createPreviewLabel(id_string)
                    }
                } else {
                    highlightButton(id_string, scene)
                    scene.currTool = id_string
                    scene.draggableCompoundPopupContainer.setCompoundPopupContainer(compoundType)
                }
            } else {

                if (scene.compoundLabelBuilder.isOpen) {
                    if (isObjectButton(id_string, scene)) {
                        scene.compoundLabelBuilder.createPreviewLabel(id_string)
                    }
                } else {
                    highlightButton(id_string, scene)
                    scene.currTool = id_string
                    scene.compoundLabelBuilder.setCompoundLabelBuilder(compoundType)
                }         
            }
        break;
        default:            
            if (isObjectButton(id,scene)) {
                createObjectLabel(id, scene)
            }
            scene.popupContainer.hideContainer()
            scene.draggablePopupContainer.hideContainer()
            
            break;
    }
}

function isObjectButton (id, scene) {
    //this.showLog("[isObjectButton] id: " + id)
    return scene.allObjectBtns.includes(id)
}

function updateItemID(scene) {
    scene.itemID += 1
}

function createObjectLabel (id,scene)  {
    showLog("[createObjectLabel] id: " + id)
    const labelName = "label-" + id
    // original size was 40
    const size = 40 
    //const size = 20 * window.devicePixelRatio
    var label = new DraggableLabel(scene, scene.gamesize.width / 2,scene.gamesize.height/2,size,size,labelName, scene.itemID)

    //const sizeRatio = 2

    //label.setScale(getResizeRatioByWidth(label,(label.width * window.devicePixelRatio) * sizeRatio));

    //console.log("label: ", label);
    showLog("label size: " + label.width);
    showLog("window.devicePixelRatio: " + window.devicePixelRatio);

   // label.setScale(getResizeRatioByWidth(label,label.width * window.devicePixelRatio ));

    updateItemID(scene)
}

export function makeThisDraggable(obj, scene, draggablePopup = null) {
    //console.log("[makeThisDraggable] obj: ", obj)
    scene.input.setDraggable(obj)
    //  The pointer has to move 16 pixels before it's considered as a drag
    scene.input.dragDistanceThreshold = 1;

    var measuringTape = null

    scene.input.on('dragstart', function (pointer, gameObject) {
        if (gameObject) {
            //gameObject.setTint(0xff0000);
        }

        /*
        measuringTape = getUtility('measuring-tape',scene,0,0)
        measuringTape.setDepth(100)
        measuringTape.x = 0
        measuringTape.y = 0
        measuringTape.alpha = 0.5
        */

    });
    scene.input.on('drag', function (pointer, gameObject, dragX, dragY) {
        if(gameObject) {
            gameObject.x = dragX;
            gameObject.y = dragY;
        }

        // *** move the preview labels
        if (draggablePopup && draggablePopup.children.length > 0) {
            showLog("gameObject x: " + gameObject.x)
            //console.log("currWidth: ", currWidth)
            //console.log("window.devicePixelRatio: ", window.devicePixelRatio)

            

            var currX = 0
            currX = dragX
            var currWidth = 0
            var prevWidth = 0

            const bottomMargin = 80 * window.devicePixelRatio
            const posY = gameObject.y + (gameObject.height/2) - bottomMargin

            showLog("draggablePopup.children: " + draggablePopup.children)
            for (let i = 0; i < draggablePopup.children.length; i++) {
                const child = draggablePopup.children[i];
                child.x = currX
                child.x += (child.width/2) * window.devicePixelRatio

                currX += (child.width) * window.devicePixelRatio
                currWidth += ((child.width) * window.devicePixelRatio)
                //prevWidth = child.width * window.devicePixelRatio
            }

            
            
            showLog("currWidth: " + currWidth)
            showLog("currX: " + currX)
            for (let i = 0; i < draggablePopup.children.length; i++) {
                const child = draggablePopup.children[i];
                child.x -= (currWidth / 2)
                child.x += draggablePopup.children[0].width / 2
                child.y = posY
            }

            /*
            measuringTape.setScale(getResizeRatioByWidth(measuringTape,currWidth),1);
            measuringTape.y = posY
            measuringTape.x = draggablePopup.children[0].x + (currWidth / 2) - (draggablePopup.children[0].width / 2)
            */
        }

     
    });
    scene.input.on('dragend', function (pointer, gameObject) {
        showLog("[makeThisDraggable] dragend ")
        if (gameObject) {
            //gameObject.clearTint();
        }
        if (measuringTape) {
            showLog("there's a measuring tape")
            measuringTape.destroy()
            measuringTape = null
        }
    });
}


export function createCompoundLabel (objects, scene) {
    showLog("[createCompoundLabel] objects: " + objects)

    var draggableCompoundLabelContainer = new DraggableCompoundLabelContainer(scene,scene.gamesize.width / 2,scene.gamesize.height/2,null, objects)
    //var compoundLabel = new DraggableCompoundLabel(this, 100,100,objects)

}

export function updateSelectedObjects(id, bool, scene){
    showLog("[updateSelectedObjects]")
    var addThis = false
    if (bool === true && !scene.selectedObjects.includes(id)) {
        scene.selectedObjects.push(id)
    } 
    if (bool === false && scene.selectedObjects.includes(id)) {
        scene.selectedObjects = scene.arrayRemove(this.selectedObjects, id)
    }
}


export function refreshThis () {
    showLog("[refreshThis]")
    history.go()
}

export function getButtonsFor(id, scene){
    showLog("[getButtonsFor] " + id)
    const allButtons = scene.allObjectBtns
    showLog("allButtons: "+ allButtons)
    var theButtons = []
    for (let i = 0; i < allButtons.length; i++) {
        const button = allButtons[i];
        const buttonType = button.split("-")[0]
        if (id === buttonType) {
            theButtons.push(button)
        } else if (buttonType === 'compound') {
            const compoundButtonType = button.split("-")[1].toLowerCase()
            if (compoundButtonType.includes(id.toLowerCase()))
            theButtons.push(button)
        }
    }
    return theButtons
}