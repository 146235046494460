import Phaser from 'phaser'
import { handleClick} from '../helper/Util'

export default class ObjectButton extends Phaser.GameObjects.Sprite {
    constructor(scene, x,y,width,height, id){
        super(scene, x, y, width, height)
        this.objbutton = null
        this.scene = scene
        this.setObjectButton(scene, x,y, id)
    }

    setObjectButton(scene, x,y, id) {
        scene.showLog("[setObjectButton] id: " + id)

        var objectbutton = scene.add.sprite(x, y, id).setInteractive()
            .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
                handleClick(id, scene)
                scene.clicked = true
            }, scene)
            .on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, () => {
                scene.clicked = false
            },scene)

        this.objbutton = objectbutton
    }
}