import Phaser from 'phaser'

export default class LoadingAnimation extends Phaser.GameObjects.Sprite {

    constructor(scene, x,y,width,height){
        super(scene, x, y, width, height)
        this.compoundUndoButton = null
        this.compoundCreateButton = null
        this.compoundUIbutton = null
        this.scene = scene
        this.bars = []
        this.createLoadingAnimation(scene, x,y, this)
    }

    createLoadingAnimation(scene, x,y, parent) {
        const radius = 24
        const height = radius * 0.6
        const width = 3

        // the center of the loading animation
        const cx = x
        const cy = y

        // start at top
        let angle = -90

        // create 12 bars each rotated and offset from the center
        for (let i = 0; i < 12; ++i)
        {
            const { x, y } = Phaser.Math.RotateAround({ x: cx, y: cy - (radius - (height * 0.5)) }, cx, cy, Phaser.Math.DEG_TO_RAD * angle)

            // create each bar with position, rotation, and alpha
            const bar = scene.add.rectangle(x, y, width, height, scene.primaryColorHex, 1)
                .setAngle(angle)
                .setAlpha(0.2)
            parent.bars.push(bar)
            // increment by 30 degrees for next bar
            angle += 30
        }

        let index = 0

        // save created tweens for reuse
        const tweens = []

        // create a looping TimerEvent
        scene.time.addEvent({
            delay: 1,
            loop: true,
            callback: () => {
                // if we already have a tween then reuse it
                if (index < tweens.length)
                {
                    const tween = tweens[index]
                    tween.restart()
                }
                else
                {
                    // make a new tween for the current bar
                    const bar = parent.bars[index]
                    const tween = scene.tweens.add({
                        targets: bar,
                        alpha: 0.1,
                        duration: 100,
                        onStart: () => {
                            bar.alpha = 1
                        }
                    })

                    tweens.push(tween)
                }

                // increment and wrap around
                ++index

                if (index >= parent.bars.length)
                {
                    index = 0
                }
            }
        })
    }

    removeLoadingAnimation(scene) {
        scene.time.destroy()
        for (let i = 0; i < this.bars.length; i++) {
            const bar = this.bars[i];
            bar.destroy()
        }
        this.bars = []
    }
}
