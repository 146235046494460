import Phaser from 'phaser'
import { makeThisDraggable, getResizeRatioByWidth} from '../helper/Util'

export default class DraggableCompoundLabelContainer extends Phaser.GameObjects.Container { 
    // children is an arary of DraggableCompoundLabel objects
    constructor(scene, x, y, children, IDs) {
        super(scene, x, y, children)
        this.scene = scene

        this.labels = []
        this.height = 0
        this.width = 0
        this.layoutLabels(x,y,this, IDs)
        this.setDraggableCompoundLabelContainer(scene, x,y,this)
    }

    layoutLabels(x,y, parent, labelIDs) {
        parent.scene.showLog("DraggableCompoundLabelContainer [layoutLabels] labelIDs: " + labelIDs)
        var currX = 0
        // load the images
        for (let i = 0; i < labelIDs.length; i++) {
            const labelID = labelIDs[i];
            const imageName = "label-" + labelID
            var label = parent.scene.add.image(0, 0, imageName)
            // original size was 0.4
            // 0.85
            const sizeRatio = 0.39

            //label.setScale(getResizeRatioByWidth(label,(label.width) * sizeRatio));
            label.setScale(getResizeRatioByWidth(label,(label.width * window.devicePixelRatio) * sizeRatio));

            const width = label.width 
            parent.width += width
            parent.height = label.height
            //const gap = ((width / 2)) * sizeRatio
            const gap = ((width / 2) * window.devicePixelRatio) * sizeRatio
            currX += gap
            label.x = currX
            currX += gap
            parent.labels.push(label)
            parent.scene.showLog("currX: " + currX + " width: " + width + " label.x: " + label.x)
        }
        // nudge all the images
        for (let i = 0; i < parent.labels.length; i++) {
            const label = parent.labels[i];
            label.x -= (parent.width/2)
        }
    }

    setDraggableCompoundLabelContainer(scene, x,y, parent) {
        scene.showLog("[setDraggableCompoundLabelContainer] x: " + x + " y: " + y)
        scene.showLog("[setDraggableCompoundLabelContainer] width: " + parent.width + " height: " + parent.height)
        var container = scene.add.container(x, y,parent.labels)
        container.setSize(parent.width,parent.height)
        container.setInteractive()
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
            scene.showLog("[DraggableCompoundLabelContainer] GAMEOBJECT_POINTER_DOWN")
            scene.clicked = true
        }, scene)
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, () => {
            scene.clicked = false
            scene.showLog("[DraggableCompoundLabelContainer] GAMEOBJECT_POINTER_UP  ")

        },scene)
        
        makeThisDraggable(container, this.scene)
        //scene.input.setDraggable(container)

    }

}