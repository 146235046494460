import Phaser from 'phaser'

import WhiteboardScene from './scenes/WhiteboardScene'

const config = {
	type: Phaser.CANVAS,
	width: window.innerWidth * window.devicePixelRatio,
	height: window.innerHeight * window.devicePixelRatio,
	backgroundColor: "#ffffff",
	pixelArt: true,
	antialias: false,
	loaderWithCredentials: false,
	resolution: 3,
	scale: {
        parent: 'canvas',
        mode: Phaser.Scale.FIT,
        autoRound: true,
    },
	physics: {
		default: 'arcade',
		arcade: {
			gravity: { y: 300 }
		}
	},
    render: {
        roundPixels: true,
    },
	scene: [WhiteboardScene]
}

export default new Phaser.Game(config)
