import Phaser from 'phaser'
import ObjectButton from './ObjectButton'
import { makeThisDraggable, getContainerPiece, getButtonsFor, getResizeRatioByWidth} from '../helper/Util'

export default class ObjectButtonsContainer extends Phaser.GameObjects.Sprite {
    constructor(scene, x,y,width,height){
        super(scene, x, y, width, height)
        this.scene = scene
        this.x = x
        this.y = y
        this.width = 0
        this.height = 0

        this.clickCatch = null
        this.containerObjArr = []
        this.objectsArr = null
        this.objButtonsArr = []

        this.windowWidth = width
        this.windowHeight = height
        // default popup tile size = 30
        this.tileSize = 30 * window.devicePixelRatio
        ///console.log("ObjectButtonsContainer this.tileSize: " + this.tileSize);
        this.maxHorizontalPieces = 12
    }

    
    setClickCatch() {
        this.scene.showLog("ObjectButtonsContainer [setClickCatch]")
        this.clickCatch = this.scene.add.rectangle(this.windowWidth / 2, this.windowHeight / 2, this.windowWidth, this.windowHeight, 0x000000, 1).setInteractive();
        this.clickCatch.alpha = 0.3
        this.clickCatch.on('pointerdown', function (pointer) {
            this.deleteClickCatch()
            if (this.containerObjArr.length > 0) {
                this.hideContainer()
            }
            if (this.objButtonsArr.length > 0) {
                this.scene.showLog("call hideObjectButtons")
                this.hideObjectButtons()
            }
        }, this);
        this.clickCatch.on('pointerup', function (pointer) {
        }, this);
    }

    deleteClickCatch() {
        this.scene.showLog("[deleteClickCatch]")
        if (this.clickCatch) {
            this.clickCatch.destroy()
        }
    }
    

    setObjectButtonsContainer() {
        var scene = this.scene

        if (this.containerObjArr.length > 0) {
            this.hideContainer()
        }

        if (this.objButtonsArr.length > 0) {
            this.scene.showLog("call hideObjectButtons")
            this.hideObjectButtons()
        }

        this.setClickCatch()


        
        scene.showLog("[setObjectButtonsContainer] this.containerObjArr.length: " + this.containerObjArr.length)
        scene.showLog("[setObjectButtonsContainer] this.objButtonsArr.length: " + this.objButtonsArr.length)
        scene.showLog("scene.currTool: " + scene.currTool)
        
        this.objectsArr = getButtonsFor(scene.currTool,scene)

        scene.showLog("objectsArr: " + this.objectsArr)

        var extraWidth = 0
        for (let i = 0; i < this.objectsArr.length; i++) {
            const obj = this.objectsArr[i];
            if (obj.includes("width2")) {
                extraWidth += 2
            }
        }

        
        var totalObjPieces = this.objectsArr.length
        extraWidth = (totalObjPieces <= this.maxHorizontalPieces) ? ((Math.ceil(extraWidth/2)) * 2) : ((Math.floor(extraWidth/2)))

        var totalPiecesHorizontal = (totalObjPieces <= this.maxHorizontalPieces) ? (totalObjPieces * 2) + (1 + extraWidth) : ((Math.ceil(totalObjPieces/2)) * 2) + (1 + extraWidth)
        var totalPiecesPerVertical = (totalObjPieces <= this.maxHorizontalPieces) ? 3 : ((Math.ceil(totalObjPieces / totalPiecesHorizontal)) * 5)
        scene.showLog("totalPiecesHorizontal: " + totalPiecesHorizontal)
        scene.showLog("totalPiecesPerVertical: " + totalPiecesPerVertical)
        scene.showLog("this.tileSize: " + this.tileSize)

        this.width = totalPiecesHorizontal * this.tileSize
        this.height = totalPiecesPerVertical * this.tileSize
        
        //var maxWidth = width / 2
        //var totalPiecesX = 0
        //var currWidth = 0
        var currCol = 0
        var currRow = 0
        var startLeft = (scene.gamesize._width / 2) - ((this.tileSize * totalPiecesHorizontal) / 2)
        var startTop = (scene.gamesize._height / 2) - ((this.tileSize * totalPiecesPerVertical) / 2)
        
        for (let i = 0; i < totalPiecesHorizontal * totalPiecesPerVertical; i++) {
            //scene.showLog("currCol: " + currCol + " currRow: " + currRow )
            var currType = 'blank'
            if (currCol >= totalPiecesHorizontal - 1) {
                // reached the right end of the popup
                //scene.showLog("reached the right end of the popup")
                if (currRow === 0) {
                    currType = 'top-right'
                    //totalPiecesX = currCol
                } else if (currRow === totalPiecesPerVertical - 1) {
                    currType = 'bottom-right'
                } else {
                    currType = 'right'
                }
                var piece = getContainerPiece(currType, scene, startLeft + (this.tileSize * currCol), startTop + (this.tileSize * currRow))
                this.containerObjArr.push(piece)
                currRow += 1
                currCol = 0
                //currWidth = 0
                
            } else {
                //scene.showLog("from the left edge")
                if (currRow === totalPiecesPerVertical - 1) {
                    scene.showLog("last row")
                    if (currCol === 0) {
                        currType = 'bottom-left'
                    } else {
                        currType = 'bottom'
                    }
                } else {
                    if (currCol === 0) {
                        if (currRow < 1) {
                            currType = 'top-left'
                        } else {
                            currType = 'left'
                        }
                    }  else if (currRow === 0) {
                        currType = 'top'
                    } 
                }
                 
                
                var piece = getContainerPiece(currType, scene, startLeft + (this.tileSize * currCol), startTop + (this.tileSize * currRow))
                this.containerObjArr.push(piece)
                currCol += 1
            }
        }
        this.showContainer()
    }

    showContainer() {
        this.scene.showLog("[showContainer]")
        this.setObjectButtons()
        this.containerObjArr.forEach(obj => {
            //console.log(obj)
            obj.setScale(getResizeRatioByWidth(obj,this.tileSize ));
            this.fadeInObject(obj)
        }, this);
    }

    hideContainer() {
        //this.scene.showLog("[clearContainer] this.containerObjArr: " + this.containerObjArr.length)
        this.hideObjectButtons()
        this.deleteClickCatch()

        for (let i = 0; i < this.containerObjArr.length; i++) {
            const obj = this.containerObjArr[i];

            this.fadeOutObject(obj)
            if (i === this.containerObjArr.length - 1) {
                this.clearContainer()
            }
        }
    }

    setObjectButtons() {
        //this.scene.showLog("[setObjectButtons] this.objectsArr: " + this.objectsArr)
        const gap = this.tileSize
        const size = this.tileSize
        var totalObjPieces = this.objectsArr.length
        var totalPiecesPerSide = (totalObjPieces <= this.maxHorizontalPieces) ? totalObjPieces : Math.ceil(totalObjPieces/2)
        var x = this.containerObjArr[0].x
        var y = this.containerObjArr[0].y + gap
        
        var currCol = 0
        var currX = 0
        var currRow = 0
        //this.scene.showLog("totalPiecesPerSide: " + totalPiecesPerSide)
        for (let i = 0; i < this.objectsArr.length; i++) {
            const obj = this.objectsArr[i];
            const width = (obj.includes("width2")) ? (this.tileSize * 2) : this.tileSize
            const currGap = (obj.includes("width2")) ? (gap * 2) : gap
            var objButton = new ObjectButton(this.scene, x + currX + currGap, y + ((size + gap) * currRow),width,this.tileSize,obj)
            objButton.objbutton.setAlpha(0)
            this.objButtonsArr.push(objButton)
            //this.scene.showLog("currX: " + currX + " width: " + width)
            if (currCol >= totalPiecesPerSide - 1) {
                currRow += 1
                currCol = 0
                currX = 0
            } else {
                currCol += 1
                currX += (width + currGap)
            }
        }
        this.showObjectButtons()

        //this.setDraggableContainer(this.scene, this.x, this.y, this)
    }

    showObjectButtons() {
        //this.scene.showLog("[showObjectButtons] this.objButtonsArr: " + this.objButtonsArr)

        this.objButtonsArr.forEach(btn => {
            btn.objbutton.setScale(getResizeRatioByWidth(btn.objbutton,this.tileSize ));
            this.fadeInObject(btn.objbutton)

        }, this);
    }

    hideObjectButtons() {
        //this.scene.showLog("[hideObjectButtons] this.objButtonsArr: " + this.objButtonsArr)
        for (let i = 0; i < this.objButtonsArr.length; i++) {
            const btn = this.objButtonsArr[i];
            this.fadeOutObject(btn.objbutton, btn)
            if (i === this.objButtonsArr.length - 1) {
                this.objButtonsArr.length = 0
            }
        }
    }

    clearContainer(){
        //this.scene.showLog("[clearContainer] this.containerObjArr: " + this.containerObjArr.length)
        this.containerObjArr.length = 0
    }

    fadeInObject(obj){
        this.scene.add.tween({
            targets: obj,
            alpha:1,
            y: obj.y - 10,
            duration: 150,
            paused: false,
            repeat: 0
        });
    }

    fadeOutObject(obj, parent=null){
        this.scene.add.tween({
            targets: obj,
            alpha:0,
            y: obj.y + 10,
            duration: 150,
            paused: false,
            repeat: 0,
            onComplete: function (){
                obj.destroy()
                if (parent) {
                    parent.destroy()
                }
            }
        });
    }

/*
    setDraggableContainer(scene, x,y, parent) {
        scene.showLog("[setDraggableContainer] x: " + x + " y: " + y)
        scene.showLog("[setDraggableContainer] width: " + parent.width + " height: " + parent.height)
        var allObjects = []

        this.containerObjArr.forEach(obj => {
            allObjects.push(obj)
        });

        
        this.objButtonsArr.forEach(obj => {
            allObjects.push(obj)
        })

        scene.showLog("[setDraggableContainer] allObjects: " + allObjects)
        

        var container = scene.add.container(x, y, allObjects)

        
        container.setSize(parent.width,parent.height)
        container.setInteractive()
        
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
            scene.showLog("[ObjectButtonsContainer] GAMEOBJECT_POINTER_DOWN")
            scene.clicked = true
        }, scene)
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, () => {
            scene.clicked = false
            scene.showLog("[ObjectButtonsContainer] GAMEOBJECT_POINTER_UP  ")

        },scene)
        makeThisDraggable(container, this.scene)
        
    }
*/
}