import Phaser from 'phaser'
import { makeThisDraggable, getResizeRatioByWidth} from '../helper/Util'

export default class DraggableLabel extends Phaser.GameObjects.Sprite {
    constructor(scene, x,y,width,height, type, itemID){
        super(scene, x, y, width, height)
        //this.objbutton = null
        this.scene = scene
        this.itemID = itemID
        this.selected = false
        this.draggingState = "off"
        this.setDraggableLabel(scene, x,y,width,height, type, this)
    }

    setDraggableLabel(scene, x,y,width, height, type, parent) {
        var label = scene.add.sprite(x, y, type).setInteractive()
            .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {

                scene.showLog("GAMEOBJECT_POINTER_DOWN draggingState: " + parent.draggingState)
                scene.clicked = true
            }, scene)
            .on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, () => {
                scene.clicked = false
                scene.showLog("GAMEOBJECT_POINTER_UP draggingState: " + parent.draggingState)
                parent.draggingState = "off"
            },scene)
            
        scene.showLog("label.width: " + label.width)
        
        //const labelsize = 80
        label.setScale(getResizeRatioByWidth(label,height * window.devicePixelRatio));


        parent.width = label.width
        parent.height = label.height

        makeThisDraggable(label, scene)

    }

    toggleSelection() {
        this.selected = !this.selected
        //this.showLog("[toggleSelection] selected: " + this.selected)
        if (this.selected) {
            //obj.alpha = 1
        } else {
            //obj.alpha = 0.01
        }
        this.scene.updateSelectedObjects(this.itemID, this.selected)
    }
}