import Phaser from 'phaser'
import { getContainerPiece, createCompoundLabel, getButtonsFor, getResizeRatioByWidth} from '../helper/Util'
import ObjectButton from './ObjectButton'
import CompoundLabelUIButton from './CompoundLabelUIButton'

export default class CompoundLabelBuilder extends Phaser.GameObjects.Sprite {
    constructor(scene, x,y,width,height, id){
        super(scene, x, y, width, height)
        this.isOpen = false
        this.id = id
        this.scene = scene
        this.windowWidth = width
        this.windowHeight = height
        this.tileSize = 30 * window.devicePixelRatio
        this.maxHorizontalPieces = 10
        this.containerObjArr = []
        this.compoundLabelButtonsArr = []
        this.compoundUIButtonsArr = []
        this.currCompoundLabelIDs = []
        this.currCompoundLabels = []

        this.scene.showLog("[CompoundLabelBuilder] id: " + this.id)
    }

    setCompoundLabelBuilder(type) {
        this.scene.showLog("[setCompoundLabelBuilder] type: " + type)
        var scene = this.scene
        this.type = type
        this.isOpen = true

        if (this.containerObjArr.length > 0) {
            this.hideContainer()
        }

        
        if (this.compoundLabelButtonsArr.length > 0) {
            this.hideObjectButtons()
        }
        
        this.setClickCatch()

        scene.showLog("[setCompoundLabelBuilder] this.containerObjArr.length: " + this.containerObjArr.length)

        this.objectsArr = getButtonsFor(type, scene)

        scene.showLog("[setCompoundLabelBuilder] this.objectsArr: " + this.objectsArr)

        const defaultVerticalPieces = 7
        var totalObjPieces = this.objectsArr.length

        var totalPiecesHorizontal = (totalObjPieces <= this.maxHorizontalPieces) ? (totalObjPieces * 2) + 1 : ((Math.ceil(totalObjPieces/2)) * 2) + 1
        var totalPiecesPerVertical = (totalObjPieces <= this.maxHorizontalPieces) ? defaultVerticalPieces : ((Math.ceil(totalObjPieces / totalPiecesHorizontal)) * (defaultVerticalPieces + 2))
        
        scene.showLog("totalPiecesHorizontal: " + totalPiecesHorizontal)
        scene.showLog("totalPiecesPerVertical: " + totalPiecesPerVertical)
        var currCol = 0
        var currRow = 0
        var startLeft = (scene.gamesize._width / 2) - ((this.tileSize * totalPiecesHorizontal) / 2) + (this.tileSize/2)
        var startTop = (scene.gamesize._height / 2) - ((this.tileSize * totalPiecesPerVertical) / 2)
        
        for (let i = 0; i < totalPiecesHorizontal * totalPiecesPerVertical; i++) {
            //scene.showLog("currCol: " + currCol + " currRow: " + currRow )
            var currType = 'blank'
            if (currCol >= totalPiecesHorizontal - 1) {
                // reached the right end of the popup
                //scene.showLog("reached the right end of the popup")
                if (currRow === 0) {
                    currType = 'top-right'
                    //totalPiecesX = currCol
                } else if (currRow === totalPiecesPerVertical - 1) {
                    currType = 'bottom-right'
                } else {
                    currType = 'right'
                }
                var piece = getContainerPiece(currType, scene, startLeft + (this.tileSize * currCol), startTop + (this.tileSize * currRow))
                this.containerObjArr.push(piece)
                currRow += 1
                currCol = 0
                //currWidth = 0
                
            } else {
                //scene.showLog("from the left edge")
                if (currRow === totalPiecesPerVertical - 1) {
                    scene.showLog("last row")
                    if (currCol === 0) {
                        currType = 'bottom-left'
                    } else {
                        currType = 'bottom'
                    }
                } else {
                    if (currCol === 0) {
                        if (currRow < 1) {
                            currType = 'top-left'
                        } else {
                            currType = 'left'
                        }
                    }  else if (currRow === 0) {
                        currType = 'top'
                    } 
                }
                 
                
                var piece = getContainerPiece(currType, scene, startLeft + (this.tileSize * currCol), startTop + (this.tileSize * currRow))
                this.containerObjArr.push(piece)
                currCol += 1
            }
        }
        this.showContainer()

        
    }

    fadeInObject(obj){
        this.scene.add.tween({
            targets: obj,
            alpha:1,
            y: obj.y - 10,
            duration: 150,
            paused: false,
            repeat: 0
        });
    }

    fadeOutObject(obj, parent=null){

        this.scene.add.tween({
            targets: obj,
            alpha:0,
            y: obj.y + 10,
            duration: 150,
            paused: false,
            repeat: 0,
            onComplete: function (){
                obj.destroy()
                if (parent) {
                    parent.destroy()
                }
            }
        });
    }

    closeCompoundLabelBuilder() {
        this.deleteClickCatch()
        if (this.containerObjArr && this.containerObjArr.length > 0) {
            this.hideContainer()
        }
        if (this.compoundLabelButtonsArr && this.compoundLabelButtonsArr.length > 0) {
            this.scene.showLog("call hideObjectButtons")
            this.hideObjectButtons()
        }

        if (this.currCompoundLabels.length > 0) {
            this.clearCompoundLabels()
        }

        if (this.currCompoundLabelIDs.length > 0) {
            this.currCompoundLabelIDs.length = 0
        }

        
    }

    setClickCatch() {
        this.scene.showLog("[setClickCatch]")
        this.clickCatch = this.scene.add.rectangle(this.windowWidth / 2, this.windowHeight / 2, this.windowWidth, this.windowHeight, 0x000000, 1).setInteractive();
        this.clickCatch.alpha = 0.7
        this.clickCatch.on('pointerdown', function (pointer) {
            this.closeCompoundLabelBuilder()
        }, this);
        this.clickCatch.on('pointerup', function (pointer) {
        }, this);
    }

    deleteClickCatch() {
        this.scene.showLog("[deleteClickCatch]")
        if (this.clickCatch) {
            this.clickCatch.destroy()
        }
    }

    showContainer() {
        this.scene.showLog("[showContainer] this.containerObjArr has " + this.containerObjArr.length)
        this.setCompoundLabelButtons()
        this.setCompoundLabelBuilderUIButtons()
        this.containerObjArr.forEach(obj => {
            obj.setScale(getResizeRatioByWidth(obj,this.tileSize ));
            this.fadeInObject(obj)
        }, this);
    }

    clearContainer(){
        //this.scene.showLog("[clearContainer] this.containerObjArr: " + this.containerObjArr.length)
        this.containerObjArr.length = 0
        this.isOpen = false
    }

    hideContainer() {
        this.hideObjectButtons()
        this.hideCompoundUIButtons()
        this.deleteClickCatch()

        for (let i = 0; i < this.containerObjArr.length; i++) {
            const obj = this.containerObjArr[i];


            this.fadeOutObject(obj)
            if (i === this.containerObjArr.length - 1) {
                this.clearContainer()
            }
        }
    }

    setCompoundLabelButtons() {
        this.scene.showLog("[setCompoundLabelButtons] this.objectsArr: " + this.objectsArr)
        const gap = this.tileSize
        const size = this.tileSize
        var totalObjPieces = this.objectsArr.length
        var totalPiecesPerSide = (totalObjPieces <= this.maxHorizontalPieces) ? totalObjPieces : Math.ceil(totalObjPieces/2)
        var x = this.containerObjArr[0].x
        var y = this.containerObjArr[0].y + gap
        
        var currCol = 0
        var currX = 0
        var currRow = 0
        this.scene.showLog("totalPiecesPerSide: " + totalPiecesPerSide)
        for (let i = 0; i < this.objectsArr.length; i++) {
            const obj = this.objectsArr[i];
            const width = (obj.includes("width2")) ? (size * 2) : size
            const currGap = (obj.includes("width2")) ? (gap * 2) : gap
            var objButton = new ObjectButton(this.scene, x + currX + currGap, y + ((size + gap) * currRow),width,this.tileSize,obj)
            objButton.objbutton.setAlpha(0)
            this.compoundLabelButtonsArr.push(objButton)
            //this.scene.showLog("currX: " + currX + " width: " + width)
            if (currCol >= totalPiecesPerSide - 1) {
                currRow += 1
                currCol = 0
                currX = 0
            } else {
                currCol += 1
                currX += (width + currGap)
            }
        }
        this.showObjectButtons()
    }

    setCompoundLabelBuilderUIButtons() {
        this.scene.showLog("[setCompoundLabelBuilderUIButtons]")

        const lastPiece = this.containerObjArr[this.containerObjArr.length-1]
        var buttonWidth = 90 
        var buttonHeight = 30 

        const tempSprite =  this.scene.add.sprite(100, 100, "undoButton")
        const resizeRatio = getResizeRatioByWidth(tempSprite,this.tileSize )
        this.scene.showLog("resizeRatio: " + resizeRatio)

        buttonWidth = (buttonWidth * resizeRatio) 
        buttonHeight = (buttonHeight * resizeRatio) 

        const buttonsX = lastPiece.x - (buttonWidth * 3) + (buttonHeight / 2)
        this.scene.showLog("buttonsX: " + buttonsX)


        tempSprite.destroy()
        
        

        const buttons = [
            { "title" : "undoButton",
            "x" : 0 },
            { "title" : "createButton",
            "x" : (buttonWidth * 1.5) }
        ]
        for (let i = 0; i < buttons.length; i++) {
            const button = buttons[i]
            const UIButton = new CompoundLabelUIButton(this.scene,buttonsX + button.x,lastPiece.y - buttonHeight,buttonWidth,buttonHeight,button.title, this)
            
            this.compoundUIButtonsArr.push(UIButton)
            UIButton.compoundUIbutton.setScale(getResizeRatioByWidth(UIButton.compoundUIbutton,this.tileSize ));
            const newButtonsX = lastPiece.x - (UIButton.compoundUIbutton.width * 2)
            this.scene.showLog("newButtonsX: " + newButtonsX)
            this.fadeInObject(UIButton.compoundUIbutton)

        }
    }

    hideCompoundUIButtons() {
        for (let i = 0; i < this.compoundUIButtonsArr.length; i++) {
            const uiButton = this.compoundUIButtonsArr[i];
            this.fadeOutObject(uiButton.compoundUIbutton)
            if (i===this.compoundUIButtonsArr.length-1) {
                this.compoundUIButtonsArr.length = 0
            }
        }

    }

    showObjectButtons() {
        this.scene.showLog("[showObjectButtons] this.compoundLabelButtonsArr: " + this.compoundLabelButtonsArr)

        this.compoundLabelButtonsArr.forEach(btn => {
            btn.objbutton.setScale(getResizeRatioByWidth(btn.objbutton,this.tileSize ));
            this.fadeInObject(btn.objbutton)
        }, this);
    }

    hideObjectButtons() {
        this.scene.showLog("[hideObjectButtons] this.compoundLabelButtonsArr: " + this.compoundLabelButtonsArr)
        for (let i = 0; i < this.compoundLabelButtonsArr.length; i++) {
            const btn = this.compoundLabelButtonsArr[i];
            this.fadeOutObject(btn.objbutton, btn)
            if (i === this.compoundLabelButtonsArr.length - 1) {
                this.compoundLabelButtonsArr.length = 0
            }
        }
    }

    createPreviewLabel(id) {
        this.scene.showLog("[createPreviewLabel] id: " + id)
        //this.scene.showLog("currCompoundLabelIDs: " + this.currCompoundLabelIDs)
        this.currCompoundLabelIDs.push(id)
        this.renderCurrCompoundLabels()
    }

    renderCurrCompoundLabels() {
        this.scene.showLog("currCompoundLabelIDs: " + this.currCompoundLabelIDs)

        this.clearCompoundLabels()
        const firstContainerBlock = this.containerObjArr[0]
        const lastContainerBLock = this.containerObjArr[this.containerObjArr.length-1]

        const containerWidth = lastContainerBLock.x - firstContainerBlock.x
        this.scene.showLog("containerWidth: " + containerWidth)

        const windowMiddle = this.windowWidth / 2


        const firstButton = this.compoundLabelButtonsArr[0]
        const lastButton = this.compoundLabelButtonsArr[this.compoundLabelButtonsArr.length-1]
        const gap = this.tileSize * 1.2
        const posY = lastButton.y + lastButton.height + gap

        //const totalWidth = size * this.currCompoundLabelIDs.length

        var currX = windowMiddle
        var currWidth = 0

        const tempSprite =  this.scene.add.sprite(100, 100, "undoButton")
        const resizeRatio = getResizeRatioByWidth(tempSprite,this.tileSize )
        
        tempSprite.destroy()
        this.scene.showLog("resizeRatio: " + resizeRatio)
        this.scene.showLog("window.devicePixelRatio: " + window.devicePixelRatio)

        //buttonWidth = (buttonWidth * resizeRatio) 
        //buttonHeight = (buttonHeight * resizeRatio) 



        for (let i = 0; i < this.currCompoundLabelIDs.length; i++) {
            const labelID = "label-" + this.currCompoundLabelIDs[i];
            this.scene.showLog("labelID: " + labelID)
            
            const label = this.scene.add.sprite(currX, posY, labelID)
            label.setScale(window.devicePixelRatio)

            label.x += (label.width/2) * window.devicePixelRatio
            currX += (label.width) * window.devicePixelRatio
            this.currCompoundLabels.push(label)
            currWidth += label.width * window.devicePixelRatio
        }
        for (let i = 0; i < this.currCompoundLabels.length; i++) {
            const label = this.currCompoundLabels[i];
            label.x -= currWidth / 2
        }

    }

    clearCompoundLabels() {
        for (let i = 0; i < this.currCompoundLabels.length; i++) {
            const label = this.currCompoundLabels[i];
            label.destroy()
        }
        this.currCompoundLabels.length=0
    }

    onUndo() {
        this.scene.showLog("[onUndo]")
        this.currCompoundLabelIDs.pop()
        this.renderCurrCompoundLabels()
    }

    onCreate() {
        //this.scene.showLog("[onCreate] this.currCompoundLabelIDs: " + this.currCompoundLabelIDs)
        createCompoundLabel(this.currCompoundLabelIDs, this.scene)
        this.closeCompoundLabelBuilder()
        //this.hideContainer()
    }

}