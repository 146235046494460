import Phaser from 'phaser'
import ObjectButton from './ObjectButton'
import { makeThisDraggable, getResizeRatioByWidth, getButtonsFor, getContainerPiece} from '../helper/Util'

export default class DraggablePopup extends Phaser.GameObjects.Sprite { 
    constructor(scene, x, y, width, height) {
        super(scene, x, y, width, height)
        this.scene = scene
        this.height = 0
        this.width = 0

        this.containerObjArr = []
        this.objectsArr = null
        this.objButtonsArr = []

        this.allObjects = []

        

        this.windowWidth = width
        this.windowHeight = height

        this.container = null

        // default popup tile size = 30
        this.tileSize = 30 * window.devicePixelRatio
        this.maxHorizontalPieces = 12


        //this.layoutLabels(x,y,this, IDs)
        //this.setDraggableCompoundLabelContainer(scene, x,y,this)
        
    }

    setContainer() {
        this.container = this.scene.add.container(this.windowWidth / 2, this.windowHeight / 2)
        this.container.setDepth(2)
    }

    setClickCatch() {
        this.scene.showLog("DraggablePopup [setClickCatch]")
        this.clickCatch = this.scene.add.rectangle(this.windowWidth / 2, this.windowHeight / 2, this.windowWidth, this.windowHeight, 0x000000, 1).setInteractive();
        this.clickCatch.alpha = 0.3
        this.clickCatch.setDepth(1)
        this.clickCatch.on('pointerdown', function (pointer) {
            this.deleteClickCatch()
            if (this.containerObjArr.length > 0) {
                this.hideContainer()
            }
            if (this.objButtonsArr.length > 0) {
                this.scene.showLog("call hideObjectButtons")
                this.hideObjectButtons()
            }
        }, this);
        this.clickCatch.on('pointerup', function (pointer) {
        }, this);
    }

    deleteClickCatch() {
        this.scene.showLog("DraggablePopup [deleteClickCatch]")
        if (this.allObjects.length > 0) {
            this.allObjects.forEach(object => {
                object.destroy()
            });
            this.allObjects = []
        }
        if (this.container) {
            this.container.removeAll()
            //this.container.destroy()
            //console.log("this.container list: ", this.container.displayList)
        }
        if (this.clickCatch) {
            this.clickCatch.destroy()
        }
    }


    showContainer() {
        this.scene.showLog("[showContainer]")
        this.setObjectButtons()
        this.containerObjArr.forEach(obj => {
            //console.log(obj)
            obj.setScale(getResizeRatioByWidth(obj,this.tileSize ));
            this.fadeInObject(obj)
        }, this);

        this.setDraggableContainer()
    }

    hideContainer() {
        //this.scene.showLog("[clearContainer] this.containerObjArr: " + this.containerObjArr.length)
        this.hideObjectButtons()
        this.deleteClickCatch()
        //this.container.active = false
        this.container.disableInteractive()

        for (let i = 0; i < this.containerObjArr.length; i++) {
            const obj = this.containerObjArr[i];

            this.fadeOutObject(obj)
            if (i === this.containerObjArr.length - 1) {
                this.clearContainer()
            }
        }
    }

    setObjectButtons() {
        this.scene.showLog("DraggablePopup [setObjectButtons] this.objectsArr: " + this.objectsArr)
        const gap = this.tileSize
        const size = this.tileSize
        var totalObjPieces = this.objectsArr.length
        var totalPiecesPerSide = (totalObjPieces <= this.maxHorizontalPieces) ? totalObjPieces : Math.ceil(totalObjPieces/2)
        
        var x = this.containerObjArr[0].x
        var y = this.containerObjArr[0].y + gap
        


        var currCol = 0
        var currX = 0
        var currRow = 0
        //this.scene.showLog("totalPiecesPerSide: " + totalPiecesPerSide)
        for (let i = 0; i < this.objectsArr.length; i++) {
            const obj = this.objectsArr[i];
            const width = (obj.includes("width2")) ? (this.tileSize * 2) : this.tileSize
            const currGap = (obj.includes("width2")) ? (gap * 2) : gap
            //(scene, x,y,width,height, id)
            var objButton = new ObjectButton(this.scene, x + currX + currGap, y + ((size + gap) * currRow),width,this.tileSize,obj)
            objButton.objbutton.setAlpha(0)
            this.objButtonsArr.push(objButton)
            this.allObjects.push(objButton.objbutton)

            //this.scene.showLog("currX: " + currX + " width: " + width)
            if (currCol >= totalPiecesPerSide - 1) {
                currRow += 1
                currCol = 0
                currX = 0
            } else {
                currCol += 1
                currX += (width + currGap)
            }
        }
        this.showObjectButtons()

        //this.setDraggableContainer(this.scene, this.x, this.y, this)
    }

    showObjectButtons() {
        //this.scene.showLog("[showObjectButtons] this.objButtonsArr: " + this.objButtonsArr)

        this.objButtonsArr.forEach(btn => {
            btn.objbutton.setScale(getResizeRatioByWidth(btn.objbutton,this.tileSize ));
            this.fadeInObject(btn.objbutton)
        }, this);
    }

    hideObjectButtons() {
        //this.scene.showLog("[hideObjectButtons] this.objButtonsArr: " + this.objButtonsArr)
        for (let i = 0; i < this.objButtonsArr.length; i++) {
            const btn = this.objButtonsArr[i];
            this.fadeOutObject(btn.objbutton, btn)
            if (i === this.objButtonsArr.length - 1) {
                this.objButtonsArr.length = 0
            }
        }
    }

    clearContainer(){
        //this.scene.showLog("[clearContainer] this.containerObjArr: " + this.containerObjArr.length)
        this.containerObjArr.length = 0
    }

    fadeInObject(obj){
        this.scene.add.tween({
            targets: obj,
            alpha:1,
            y: obj.y - 10,
            duration: 150,
            paused: false,
            repeat: 0
        });
    }

    fadeOutObject(obj, parent=null){
        this.scene.add.tween({
            targets: obj,
            alpha:0,
            y: obj.y + 10,
            duration: 150,
            paused: false,
            repeat: 0,
            onComplete: function (){
                obj.destroy()
                if (parent) {
                    parent.destroy()
                }
            }
        });
    }

    setObjectButtonsContainer() {
        this.scene.showLog(" DraggablePopup [setObjectButtonsContainer]")
        var scene = this.scene

        if (this.containerObjArr.length > 0) {
            this.hideContainer()
        }

        if (this.objButtonsArr.length > 0) {
            this.scene.showLog("call hideObjectButtons")
            this.hideObjectButtons()
        }

        this.setClickCatch()

        if (this.container === null) {
            this.setContainer()
        }
        
        scene.showLog("DraggablePopup [setObjectButtonsContainer] this.containerObjArr.length: " + this.containerObjArr.length)
        scene.showLog("DraggablePopup [setObjectButtonsContainer] this.objButtonsArr.length: " + this.objButtonsArr.length)
        scene.showLog("DraggablePopup scene.currTool: " + scene.currTool)
        
        this.objectsArr = getButtonsFor(scene.currTool,scene)

        scene.showLog("DraggablePopup objectsArr: " + this.objectsArr)

        var extraWidth = 0
        for (let i = 0; i < this.objectsArr.length; i++) {
            const obj = this.objectsArr[i];
            if (obj.includes("width2")) {
                extraWidth += 2
            }
        }

        
        var totalObjPieces = this.objectsArr.length
        extraWidth = (totalObjPieces <= this.maxHorizontalPieces) ? ((Math.ceil(extraWidth/2)) * 2) : ((Math.floor(extraWidth/2)))

        var totalPiecesHorizontal = (totalObjPieces <= this.maxHorizontalPieces) ? (totalObjPieces * 2) + (1 + extraWidth) : ((Math.ceil(totalObjPieces/2)) * 2) + (1 + extraWidth)
        var totalPiecesPerVertical = (totalObjPieces <= this.maxHorizontalPieces) ? 3 : ((Math.ceil(totalObjPieces / totalPiecesHorizontal)) * 5)
        scene.showLog("totalPiecesHorizontal: " + totalPiecesHorizontal)
        scene.showLog("totalPiecesPerVertical: " + totalPiecesPerVertical)
        scene.showLog("this.tileSize: " + this.tileSize)

        this.width = totalPiecesHorizontal * this.tileSize
        this.height = totalPiecesPerVertical * this.tileSize
        
        //var maxWidth = width / 2
        //var totalPiecesX = 0
        //var currWidth = 0
        var currCol = 0
        var currRow = 0
        /*
        var startLeft = -this.tileSize
        var startTop = -this.tileSize
        */
        var startLeft = -((this.width / 2) - (this.tileSize))
        var startTop = -this.tileSize
        /*
var startLeft = (scene.gamesize._width / 2) - ((this.tileSize * totalPiecesHorizontal) / 2)
        var startTop = (scene.gamesize._height / 2) - ((this.tileSize * totalPiecesPerVertical) / 2)
        */
        
        for (let i = 0; i < totalPiecesHorizontal * totalPiecesPerVertical; i++) {
            //scene.showLog("currCol: " + currCol + " currRow: " + currRow )
            var currType = 'blank'
            if (currCol >= totalPiecesHorizontal - 1) {
                // reached the right end of the popup
                //scene.showLog("reached the right end of the popup")
                if (currRow === 0) {
                    currType = 'top-right'
                    //totalPiecesX = currCol
                } else if (currRow === totalPiecesPerVertical - 1) {
                    currType = 'bottom-right'
                } else {
                    currType = 'right'
                }
                var piece = getContainerPiece(currType, scene, startLeft + (this.tileSize * currCol), startTop + (this.tileSize * currRow))
                this.containerObjArr.push(piece)
                this.allObjects.push(piece)
                currRow += 1
                currCol = 0
                //currWidth = 0
                
            } else {
                //scene.showLog("from the left edge")
                if (currRow === totalPiecesPerVertical - 1) {
                    scene.showLog("last row")
                    if (currCol === 0) {
                        currType = 'bottom-left'
                    } else {
                        currType = 'bottom'
                    }
                } else {
                    if (currCol === 0) {
                        if (currRow < 1) {
                            currType = 'top-left'
                        } else {
                            currType = 'left'
                        }
                    }  else if (currRow === 0) {
                        currType = 'top'
                    } 
                }
                 
                
                var piece = getContainerPiece(currType, scene, startLeft + (this.tileSize * currCol), startTop + (this.tileSize * currRow))
                this.containerObjArr.push(piece)
                this.allObjects.push(piece)
                currCol += 1
            }
        }
        this.showContainer()
    }

    setDraggableContainer() {
       // this.scene.showLog("[setDraggableContainer] this: " + this + " this.scene: " + this.scene + " containerObjArr: " + this.containerObjArr)
        
        const x = this.allObjects[0].x 
        const y = this.allObjects[0].y
       //this.scene.showLog("[setDraggableContainer] x: " + x + " y: " + y)
        this.scene.showLog("[setDraggableContainer] this.width: " + this.width + " this.height: " + this.height)
        //console.log("[setDraggableContainer]  this.container: " , this.container)

        this.container.disableInteractive()

        

        /*
        if (this.container === null) {
            this.container = this.scene.add.container(this.windowWidth / 2, this.windowHeight / 2,this.allObjects)
        } else {
            this.container.add(this.allObjects)
        }
        */

        
        //this.container.x = 100

        this.container.add(this.allObjects)
        this.container.setSize(this.width,this.height)
        //this.container.input.hitArea.width = this.width

        if (this.container.input) {
            //console.log("[setDraggableContainer]  this.container.input.hitArea: " , this.container.input.hitArea)
            this.container.input.hitArea.width = this.width
            this.container.input.hitArea.height = this.height

        }

       // this.scene.showLog("[setDraggableContainer] this.container.width: " + this.container.width + " this.container.height: " + this.container.height)
       // this.scene.showLog("[setDraggableContainer] this.container.displayWidth: " + this.container.displayWidth + " this.container.displayHeight: " + this.container.displayHeight)


        this.container.active = true
        this.container.setInteractive()
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
            this.scene.showLog("[DraggablePopup] GAMEOBJECT_POINTER_DOWN " + this.container)
            this.scene.clicked = true
        }, this.scene)
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, () => {
            this.scene.clicked = false
            this.scene.showLog("[DraggablePopup] GAMEOBJECT_POINTER_UP  ")

        },this.scene)

        makeThisDraggable(this.container, this.scene)

        this.container.x = (this.windowWidth / 2) - (this.tileSize / 2)
        this.container.y = (this.windowHeight / 2) - (this.tileSize / 2)
        

        /*
        this.container.setSize(this.width,this.height)
        this.container.setInteractive()
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
            this.scene.showLog("[DraggablePopup] GAMEOBJECT_POINTER_DOWN")
            this.scene.clicked = true
        }, this.scene)
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, () => {
            this.scene.clicked = false
            this.scene.showLog("[DraggablePopup] GAMEOBJECT_POINTER_UP  ")

        },this.scene)

        makeThisDraggable(this.container, this.scene)
        */

    }

}